import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { Message } from 'element-ui'

// 存储是否正在更新 Token 的状态
// let isRefreshing = false
// 存储因为等待 Token 刷新而挂起的请求
// let requests = []

function redirectLogin () {
  router.push({
    name: 'Login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}

const request = axios.create({
  baseURL: '/api/v1'
})

request.interceptors.request.use(function (config) {
  const { user } = store.state
  if (user && user.access_token) {
    config.headers.Authorization = 'Bearer ' + user.access_token
  }

  return config
})

request.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    const { status } = error.response
    // 还需单独处理 token 过期退出的情况
    if (status === 401) {
      // token 不存在
      if (!store.state.user) {
        redirectLogin()
        return Promise.reject(error)
      }
      /* if (isRefreshing) {
        return requests.push(error.config)
      }
      isRefreshing = true */
      // token 错误或过期
      return request({
        method: 'PUT',
        url: '/authorizations/current'
      }).then(res => {
        if (res.data && res.data.access_token) {
          store.commit('setUser', res.data)
          /* requests.forEach(config => {
            return request(config)
          })
          requests = [] */
          // 将本次请求发送
          return request(error.config)
        } else {
          store.commit('setUser', null)
          redirectLogin()
          return Promise.reject(error)
        }
      }).catch(() => {
        store.commit('setUser', null)
        redirectLogin()
        Message.error('登录已过期请重新登录')
        return Promise.reject(error)
      }).finally(() => {
        // 请求发送完毕. 响应处理完毕. 将刷新状态更改为 false
        // isRefreshing = false
      })
    }
  } else if (error.request) {
    Message.error('请求超时请重试')
  } else {
    Message.error(error.message)
  }

  return Promise.reject(error)
})

export default request
